<template lang="pug">
    .factors
        .factor(
            v-for="item in factorsData",
            :key="item.title"
        )
            ui-paragraph.title(v-html="item.title")
            .factor-item(
                v-for="factorItem in item.items",
                :key="factorItem.text"
            )
                .icon-wrap
                    img(:src="require(`../../../../../assets/images/articles/4/factors/${factorItem.image}.svg`)", :alt="factorItem.text")
                ui-paragraph.text(v-html="factorItem.text")
</template>

<script>
import UiParagraph from '../../../../ui/Paragraph.vue';

export default {
    name: 'factors',
    components: {
        UiParagraph,
    },
    computed: {
        factorsData() {
            return [
                {
                    title: 'Ложноположительная реакция на&nbsp;пробу Манту',
                    items: [
                        {
                            image: '1',
                            text: 'После вакцинации БЦЖ',
                        },
                        {
                            image: '2',
                            text: 'При наличии других микобактерий',
                        },
                    ],
                },
                {
                    title: 'Ложноотрицательная реакция на&nbsp;пробу Манту',
                    items: [
                        {
                            image: '3',
                            text: 'При <nobr>ВИЧ-инфекции</nobr> и&nbsp;других факторах подавленного иммунитета',
                        },
                    ],
                },
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
.factors {
    width: 100%;
    max-width: 750px;
    margin: rem(45) auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: flex-start;

    .factor {
        width: 50%;

        &:nth-child(2n - 1) {
            padding-right: rem(48);
            border-right: 2px solid var(--violet);
        }

        &:nth-child(2n) {
            padding-left: rem(48);
            border-left: 2px solid var(--violet);
        }

        .title {
            font-weight: 700;
        }

        .factor-item {
            width: 100%;
            margin: rem(32) 0 rem(44) 0;

            .icon-wrap {
                width: 100%;

                img {
                    max-height: rem(86);
                    margin: 0 auto;
                    display: block;
                    object-fit: contain;
                }
            }

            .text {
                width: 100%;
                margin-top: rem(18);
                text-align: center;
            }
        }
    }

    @media (--viewport-tablet) {
        max-width: unset;
        margin: rem-mobile(32) auto;
        padding: 0 var(--offset-x);
        display: block;

        .factor {
            width: 100%;

            &:nth-child(2n - 1) {
                padding-right: 0;
                border-bottom: 2px solid var(--violet);
                border-right: none;
            }

            &:nth-child(2n) {
                padding-top: rem-mobile(44);
                padding-left: 0;
                border-top: 2px solid var(--violet);
                border-left: none;
            }

            .title {
                text-align: center;
            }

            .factor-item {
                margin: rem-mobile(32) 0 rem-mobile(44) 0;

                .icon-wrap {
                    img {
                        max-height: rem-mobile(100);
                    }
                }

                .text {
                    margin-top: rem-mobile(18);
                }
            }
        }
    }
}
</style>
